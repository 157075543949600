import React, { SVGProps, useState } from "react";
import { Divider, Layout, Menu, Spin } from "antd";
import { useNavigate } from "react-router-dom";

import {
  NotificationOutlined,
  FileSearchOutlined,
  AuditOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import { useUserState } from "../../common/UserContext";
import { isAdmin, isOnboarding, isSuperAdmin } from "../../types/UserGroup";
import SubMenu from "antd/lib/menu/SubMenu";
import { isUser } from "../../types/UserGroup";
import { AppHeader } from "./components/AppHeader";
import {ReactComponent as DashboardIcon} from "../../assets/icons/svg/insights.svg"
import {ReactComponent as CalendarIcon} from "../../assets/icons/svg/calendar.svg"
import {ReactComponent as VendorsIcon} from "../../assets/icons/svg/dataset.svg"
import {ReactComponent as NegotiationIcon} from "../../assets/icons/svg/group.svg"
import {ReactComponent as BriefsIcon} from "../../assets/icons/svg/briefs.svg"
import {ReactComponent as FormsIcon} from "../../assets/icons/svg/forms.svg"
import {ReactComponent as FeaturesIcon} from "../../assets/icons/svg/inspect.svg"
import {ReactComponent as OrgsIcon} from "../../assets/icons/svg/global.svg"
import {ReactComponent as ImpersonationIcon} from "../../assets/icons/svg/list.svg"
import {ReactComponent as UsersIcon} from "../../assets/icons/svg/users.svg"
import {ReactComponent as SettingssIcon} from "../../assets/icons/svg/settings.svg"
import {ReactComponent as NotificationsIcon} from "../../assets/icons/svg/notifications.svg"

const {  Content, Footer, Sider } = Layout;

interface ScreenLayout {
  mainPanel: any;
  selected: any;
  collapsed: any;
}

const MenuIconStyle:SVGProps<SVGSVGElement>={
  width:16,
  height:16,
  fill:"#1890FF",
  style:{
    verticalAlign:"middle"
  }
}

const MainLayout: React.FC<ScreenLayout> = ({ mainPanel, selected, collapsed }) => {
  const navigate = useNavigate();

  const [isSelected, setIsSelected] = useState(collapsed);
  const { me } = useUserState();

  const general = isUser(me.roles || []) ? (
    <>
      <Menu.Item
        id="nav-dashboard"
        key="Dashboard"
        icon={<DashboardIcon {...MenuIconStyle}/>}
        onClick={() => navigate("/app/dashboard")}
      >
        Dashboard
      </Menu.Item>
      <Menu.Item
        id="nav-calendar"
        key="Calendar"
        icon={<CalendarIcon {...MenuIconStyle} />}
        onClick={() => navigate("/app/calendar")}
      >
        Calendar
      </Menu.Item>
      <Menu.Item
        key="vendors"
        icon={<VendorsIcon {...MenuIconStyle}/>}
        onClick={() => navigate("/app/vendor")}
      >
        Vendors
      </Menu.Item>
      <Menu.Item
        id="nav-negotiations"
        key="Negotiations"
        icon={<NegotiationIcon {...MenuIconStyle} />}
        onClick={() => navigate("/app/negotiations")}
      >
        Negotiation intelligence
      </Menu.Item>
      <Menu.Item
        id="nav-briefs"
        key="SupplierSheets"
        icon={<BriefsIcon {...MenuIconStyle} />}
        onClick={() => navigate("/app/supplier")}
      >
        Buyer Briefs
      </Menu.Item>
    </>
  ) : (
    <></>
  );

  const admin = isAdmin(me.roles || []) ? (
    <>
      <Menu.Item
        id="nav-forms"
        key="newOrder"
        icon={<FormsIcon {...MenuIconStyle} />}
        onClick={() => navigate("/app/order/new")}
      >
        Add Contracts
      </Menu.Item>
      <Menu.Item
        key="users"
        icon={<UsersIcon {...MenuIconStyle} />}
        onClick={() => navigate("/app/admin/users")}
      >
        Users/Groups
      </Menu.Item>
    </>
  ) : (
    <></>
  );

  const superAdminLinks = isSuperAdmin(me.roles || []) ? (
    <>
      <Divider />
      <SubMenu key="reviewMenues" icon={<FileSearchOutlined style={{color:"#1890FF"}}/>} title="Form Reviews">
        <Menu.Item
          key={"reviewOpen"}
          icon={<AuditOutlined />}
          onClick={() => navigate("/app/review")}
        >
          Open Form Reviews
        </Menu.Item>
        <Menu.Item
          key={"reviewDone"}
          icon={<FileDoneOutlined />}
          onClick={() => navigate("/app/review/completed")}
        >
          Completed Form Reviews
        </Menu.Item>
      </SubMenu>
      <Menu.Item
        key="orgs"
        icon={<OrgsIcon {...MenuIconStyle}/>}
        onClick={() => navigate("/app/admin/orgs")}
      >
        Organizations
      </Menu.Item>
      <Menu.Item
        key="impersonation"
        icon={<ImpersonationIcon {...MenuIconStyle}/>}
        onClick={() => navigate("/app/admin/impersonation")}
      >
        Impersonation
      </Menu.Item>
      <Menu.Item
        key="industry"
        icon={<AuditOutlined style={{color:"#1890FF"}} />}
        onClick={() => navigate("/app/superadmin/industry")}
      >
        Industry
      </Menu.Item>
      <Menu.Item
        key="notifications"
        icon={<NotificationsIcon {...MenuIconStyle}/>}
        onClick={() => navigate("/app/notifications")}
      >
        Notifications
      </Menu.Item>
      <Menu.Item
        key="flags"
        icon={<FeaturesIcon {...MenuIconStyle}/>}
        onClick={() => navigate("/app/admin/flags")}
      >
        Feature Flags
      </Menu.Item>
    </>
  ) : (
    <></>
  );

  //TODO: Not currently needed. We may expose this again once we open reviews to regular users.
  //At present, all reviews will occur by InfinityLoop staff
  // const reviewer = isReviewer(me.roles || []) ? (
  //     <>
  //         <Divider />{" "}
  //     </>
  // ) : (
  //     <></>
  // );

    const isOnboardingUser = isOnboarding(me.roles)
    const menu = isOnboardingUser ? (
        <>
            <Menu.Item
                key="newOrder"
                icon={<FormsIcon {...MenuIconStyle} />}
                onClick={() => navigate("/app/order/new")}
            >
                Add Contracts
            </Menu.Item>
        </>
    ) : (
        <>
            {general}
            <Divider />
            <Menu.Item
                key="preferences"
                icon={<SettingssIcon {...MenuIconStyle} />}
                onClick={() => navigate("/app/preferences")}
            >
                Preferences
            </Menu.Item>
            {admin}
            {superAdminLinks}
        </>
    );


    const onCollapse = () => {
        setIsSelected(!isSelected);
    };
    return (
        <Spin spinning={me.email === undefined}>
            <div style={{ minWidth: 1400 }}>
                <AppHeader me={me} disabled={isOnboardingUser}></AppHeader>
                <Layout style={{ minHeight: "100vh" }}>
                    <Sider
                        collapsible
                        collapsed={isSelected}
                        onCollapse={onCollapse}
                        theme="light"
                        className="sidebar"
                    >
                        <Menu theme="light" defaultSelectedKeys={[selected]} mode="inline">
                            {menu}
                        </Menu>
                    </Sider>
                    <Layout className="site-layout">
                        <Content style={{ minHeight: 800 }}>{mainPanel}</Content>
                        <Footer style={{ textAlign: "center" }}>InfinityLoop, Inc &copy;</Footer>
                    </Layout>
                </Layout>
            </div>
        </Spin>
    );
};

export default MainLayout;
