import React from "react";
import { Tooltip } from "antd";
import { SvgIcon } from "./SvgIcon";
import styles from "./GenericGreyButton.module.less";

interface GenericGreyButtonProps {
    label?: string;
    tip?: string;
    onClick?: () => void;
}

export const GenericGreyButton: React.FC<GenericGreyButtonProps> = ({ label, tip, onClick }) => {
    return (
        <div className={styles.main} onClick={onClick}>
            <div>{label}</div>
            <Tooltip title={tip}>
                <SvgIcon name="help" />
            </Tooltip>
        </div>
    );
};
