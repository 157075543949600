import React from "react";
import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import { BreadcrumbsBar } from "../../../common/components/BreadcrumbsBar";
import { OrderFormDownloadButton } from "../../../common/components/OrderFormDownloadButton";

interface OrderFormScoreHeaderProps {
    orderFormId: number;
    orderFormName: string;
}
export const OrderFormScoreHeader: React.FC<OrderFormScoreHeaderProps> = ({
    orderFormId,
    orderFormName,
}) => {
    const breadcrumbs = [
        {
            name: "Contracts",
            path: "/app/order/new",
        },
        {
            name: orderFormName,
            path: `/app/order/score/${orderFormId}`,
        },
    ];

    return (
        <>
            <BreadcrumbsBar breadcrumbs={breadcrumbs} />
            <Row>
                <Col flex={1}>
                    <span>Past contract insights - </span>
                    <Link to={`/app/order/${orderFormId}`}>{orderFormName}</Link>
                </Col>
                <Col>
                    <OrderFormDownloadButton orderFormId={orderFormId} />
                </Col>
            </Row>
        </>
    );
};
