import React from "react";
import { Row, Col } from "antd";
import { VendorSelectInput } from "../../vendor/VendorInput";
import { Vendor } from "../../../types/VendorTypes";

interface OrderFormPageHeaderProps {
    onSelect?: (vendors: Vendor[]) => void;
}

export const OrderFormPageHeader: React.FC<OrderFormPageHeaderProps> = ({ onSelect }) => (
    <Row align="middle">
        <Col span={18}>
            <div style={{ fontSize: 16 }}>Contracts</div>
            <div style={{ color: "#666" }}>
                Leverage our advanced ML model to analyze contract scores, mitigate risk and uncover
                savings opportunities.
            </div>
        </Col>
        <Col span={6}>
            <VendorSelectInput onSelect={onSelect} />
        </Col>
    </Row>
);
