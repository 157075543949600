import { Button, Row, Col, Space, Tooltip } from "antd";
import React, { useState, useEffect } from "react";
import OrderFormLayout from "./OrderFormLayout";
import { useParams } from "react-router-dom";
import { getOrderForm, updateOrderForm } from "../../common/api/OrderFormClient";
import { OrderForm, OrderFormStatus } from "../../types/OrderFormTypes";
import { ResourceType } from "../../types/ResourceType";
import { TagPanel } from "../tag/TagPanel";
import { canWrite } from "../../types/AccessControlTypes";
import { handleError } from "../../common/ErrorHandling";
import { getPdfFile } from "./../../common/api/OrderFormClient";
import { SvgIcon } from "../../common/components/SvgIcon";
import { CommentsPanel } from "../comments/CommentsPanel";
import { UtilizationMeasurePanel } from "./components/UtilizationMeasurePanel";
import { OrderFormTablePanel } from "./components/OrderFormTablePanel";
import { SkuTablePanel } from "./components/SkuTablePanel";
import { getVendors } from "../../common/api/VendorClient";
import { Vendor } from "../../types/VendorTypes";
import { AttachmentsPanel } from "../attachments/AttachmentsPanel";

export const OrderFormDeteailsV2: React.FC = () => {
    const params = useParams();
    const [disabled, setDisabled] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const [orderForm, setOrderForm] = useState({} as OrderForm);
    const [orderFormId] = useState(Number(params.orderFormId) || 0);
    const [showMessages, setShowMessages] = useState(false);
    const [vendor, setVendor] = useState({} as Vendor);
    const [showAttachments, setShowAttachments] = useState(false);
    const [, setStatusLocked] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [reload, setReload] = useState(0);

    const sumbmitOrderForm = async (orderForm: OrderForm) => {
        await updateOrderForm(orderForm);
        setReload(reload + 1);
    };

    const loadVendor = async (vendorKey: string) => {
        try {
            const attachments = await getVendors([vendorKey]);
            if (attachments.length) {
                setVendor(attachments[0]);
            }
        } catch (error) {
            handleError(error);
        }
    };

    useEffect(() => {
        getOrderForm(orderFormId)
            .then((order) => {
                const _readOnly = !canWrite(order.access);
                setReadOnly(_readOnly);
                setOrderForm(order.orderForm);
            })
            .catch((error) => {
                handleError(error);
                setHasError(true);
            });
    }, [reload]);

    useEffect(() => {
        const _statusLocked =
            orderForm.status === OrderFormStatus.RENEWED ||
            orderForm.status === OrderFormStatus.WILL_NOT_RENEW;
        setStatusLocked(_statusLocked);
        setDisabled(readOnly || _statusLocked);

        if (orderForm.vendorKey) {
            loadVendor(orderForm.vendorKey);
        }
    }, [orderForm]);

    if (hasError) {
        return <></>;
    }

    const messagesDrawer = (
        <>
            {vendor.id ? (
                <CommentsPanel
                    resourceId={vendor.id}
                    visible={showMessages}
                    resourceType={ResourceType.Vendor}
                    onClose={() => setShowMessages(false)}
                />
            ) : null}
        </>
    );

    const attachmentsDrawer = (
        <>
            {vendor.id ? (
                <AttachmentsPanel
                    resourceId={vendor.id}
                    resourceType={ResourceType.Vendor}
                    open={showAttachments}
                    onClose={() => setShowAttachments(false)}
                />
            ) : null}
        </>
    );

    const onClickForm = async () => {
        const res: any = await getPdfFile(orderFormId);
        if (res && "url" in res) {
            window.open(res.url, "_blank");
        }
    };

    const toolbox = (
        <>
            <Space size={10}>
                <Button
                    type="link"
                    size={"small"}
                    onClick={onClickForm}
                    icon={
                        <Tooltip title={"Download contract pdf"}>
                            <SvgIcon name="download" />
                        </Tooltip>
                    }
                ></Button>
                <Button
                    type="link"
                    size={"small"}
                    onClick={() => {
                        setShowMessages(true);
                    }}
                    icon={
                        <Tooltip title={"Show comments"}>
                            <SvgIcon name="message01" />
                        </Tooltip>
                    }
                ></Button>
                <Button
                    type="link"
                    size={"small"}
                    onClick={() => {
                        setShowAttachments(true);
                    }}
                    icon={
                        <Tooltip title={"Show attachments"}>
                            <SvgIcon name="document" />
                        </Tooltip>
                    }
                ></Button>
            </Space>
        </>
    );

    const toolPanel = (
        <div style={{ paddingBottom: 10, paddingTop: 10 }}>
            <Row justify="start" align="middle">
                <Col span={24}>
                    <TagPanel
                        resourceId={orderFormId}
                        resourceType={ResourceType.OrderForm}
                        readOnly={disabled}
                    />
                </Col>
            </Row>
        </div>
    );

    const content = (
        <div>
            {messagesDrawer}
            {attachmentsDrawer}
            <Row>
                <Col span={24}>{toolPanel}</Col>
            </Row>
            <OrderFormTablePanel
                orderFormId={orderFormId}
                orderForm={orderForm}
                onChange={sumbmitOrderForm}
                disabled={disabled}
            />
            <UtilizationMeasurePanel
                orderFormId={orderFormId}
                vendorId={vendor.id}
                utilizationScore={orderForm.utilizationScore}
                onChange={() => setReload(reload + 1)}
            />
            <SkuTablePanel orderFormId={orderFormId} />
        </div>
    );

    return (
        <OrderFormLayout
            currentPath={[
                {
                    name: "Contracts",
                    path: "/app/order/new",
                },
                {
                    name: `${orderForm.name || ""}`,
                    path: `/app/order/${orderForm.id}`,
                },
            ]}
            contentPanel={content}
            toolbox={toolbox}
        />
    );
};
