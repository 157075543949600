import React, { useState } from "react";
import Dragger, { DraggerProps } from "antd/lib/upload/Dragger";
import { Spin, UploadProps } from "antd";
import styles from "./UploadButton.module.less";
import { GenericGreyButton } from "./GenericGreyButton";

interface UploadButtonProps extends DraggerProps {
    title: string;
    description: string;
}

export const UploadButton: React.FC<UploadButtonProps> = ({
    title,
    description,
    onChange,
    ...restProps
}) => {
    const [spinning, setSpinning] = useState(false);
    const handleChange: UploadProps["onChange"] = (info) => {
        setSpinning(true);
        if (info.file.status !== "uploading") {
            setSpinning(false);
        }
        onChange?.(info);
    };

    return (
        <Spin spinning={spinning}>
            <Dragger onChange={handleChange} {...restProps} className={styles.dragger}>
                <GenericGreyButton label={title} tip={description} />
            </Dragger>
        </Spin>
    );
};
