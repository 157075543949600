import { Col, message, Row, Space, Typography } from "antd";
import { UploadChangeParam, UploadFile } from "antd/lib/upload";
import React, { useEffect, useState } from "react";
import { listOrders } from "../../common/api/OrderFormClient";
import { geturl } from "../../common/api/SafeClient";
import { listVendors } from "../../common/api/VendorClient";
import { UploadButton } from "../../common/components/UploadButton";
import { handleError } from "../../common/ErrorHandling";
import { ContractCreateButton } from "./components/ContractCreateButton";
import { ContractStatistics } from "./components/ContractStatistics";

interface OrderFormAddTopPanelProps {
    style?: React.CSSProperties;
    onUpload?: (status?: UploadChangeParam<UploadFile>) => void;
    showUpload?: boolean;
    refresh?:number;
}

export const OrderFormAddTopPanel: React.FC<OrderFormAddTopPanelProps> = ({
    style,
    onUpload,
    showUpload = true,
    refresh,
}) => {
    const [contractCount, setContractCount] = useState(0);
    const [supplierCount, setSupplierCount] = useState(0);

    const onChangeUpload = (status: UploadChangeParam<UploadFile>) => {
        if (
            (
                status.fileList.map((value) => {
                    return value.status === "done";
                }) as boolean[]
            ).reduce((prev, current) => {
                return prev && current;
            })
        ) {
            onUpload?.(status);
            message.success("Files successfully uploaded");
            return;
        }

        const file = status.file;
        if (file.error) {
            handleError(
                file.response,
                `Upload failed`,
                <Space direction="vertical">
                    <span style={{ whiteSpace: "nowrap" }}>
                        File: <Typography.Text italic>{file.name}</Typography.Text>
                    </span>
                    <span>
                        Please{" "}
                        <Typography.Link onClick={() => window.location.reload()}>
                            refresh the page
                        </Typography.Link>{" "}
                        and try again.
                    </span>
                </Space>,
                { width: "auto" }
            );
        }
    };

    const uploadPDF = (
        <UploadButton
            accept=".pdf"
            multiple={true}
            maxCount={10}
            action={geturl("/api/orderform/pdf")}
            showUploadList={false}
            onChange={onChangeUpload}
            title="Upload PDF contracts"
            description="Click or drag files to be analyzed by our automated contract detection algorithms and validated by our staff."
            withCredentials
        />
    );

    const bulkUpload = (
        <UploadButton
            accept=".zip"
            multiple={true}
            maxCount={10}
            action={geturl("/api/orderform/zip")}
            showUploadList={false}
            onChange={onChangeUpload}
            title="Upload ZIP PDF contracts"
            description="Click or drag files to be analyzed by our automated contract detection algorithms and validated by our staff."
            withCredentials
        />
    );
    const actions = [
        {
            name: "uploadPDF",
            action: uploadPDF,
            isUpload: true,
        },
        {
            name: "bulkUpload",
            action: bulkUpload,
            isUpload: true,
        },
        {
            name: "createButton",
            action: <ContractCreateButton />,
            isUpload: false,
        },
    ];
    const filteredActions = showUpload ? actions : actions.filter((item) => !item.isUpload);

    const loadData = async () => {
        const [contracts, vendors] = await Promise.all([
            listOrders({ pageSize: 10, current: 1 }, [], []),
            listVendors({ pageSize: 10, current: 1 }, [], []),
        ]);

        setContractCount(contracts.pagination.total);
        setSupplierCount(vendors.pagination.total);
    };

    useEffect(() => {
        loadData();
    }, [ refresh ]);

    return (
        <Row gutter={16} style={style}>
            <Col span={5}>
                <Row gutter={[0, 8]}>
                    {filteredActions.map((item) => (
                        <Col span={24} key={item.name}>
                            {item.action}
                        </Col>
                    ))}
                </Row>
            </Col>
            <Col span={19}>
                <ContractStatistics contractCount={contractCount} supplierCount={supplierCount} />
            </Col>
        </Row>
    );
};
