import React, { useState } from "react";
import { OrderFormNewModal } from "../OrderFormNewModal";
import { GenericGreyButton } from "../../../common/components/GenericGreyButton";

export const ContractCreateButton = (props: {}) => {
    const [showCreationForm, setShowCreationForm] = useState(false);

    return (
        <>
            <OrderFormNewModal
                showForm={showCreationForm}
                onClose={() => setShowCreationForm(false)}
            />
            <GenericGreyButton
                label="Create individual contracts"
                tip="Manually fill the required contract information. Once filled, you will be
                        redirected to the form for review and/or revision."
                onClick={() => {
                    setShowCreationForm(true);
                }}
            />
        </>
    );
};
