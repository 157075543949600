import { Button, Card, Modal, Space, Tag } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { listReviews, deleteReview, editReview } from "../../common/api/ReviewClient";
import { getUsers } from "../../common/api/UserClient";
import { SortFilterPaged } from "../../common/CommonInterfaces";
import { SortableFilteringTable } from "../../common/components/SortableFilteringTable";
import {
    FilterGroup,
    FilterGroupType,
    FilterOperand,
    PagerObject,
    Sort,
} from "../../types/DataFetchingTypes";
import { DocumentReview, ExpandedDocumentReview, ReviewStatus } from "../../types/ReviewTypes";
import { isOnboarding, User } from "../../types/UserGroup";
import { formatDetailTimeString } from "../date/DateOp";
import MainLayout from "../navigation/MainLayout";
import { ReviewStatusToTag } from "./ReviewStatus";
import { useUserState } from "../../common/UserContext";
import { isSuperAdmin } from "../../types/UserGroup";
import { Vendor } from "../../types/VendorTypes";
import { getVendors } from "../../common/api/VendorClient";
import { SimulationScore } from "../dashboard-v2/components/SimulationScore";
import { roundToTwoDecimals } from "../../common/Number";
import { Error } from "../../types/Error";
import { ErrorInfoModal } from "../../common/components/ErrorInfoModal";
import { SvgIcon } from "../../common/components/SvgIcon";
import { ReviewUpdateModal } from "./components/ReviewUpdateModal";

const pager = {
    filter: [] as FilterGroup[],
    sort: [] as Sort[],
    pager: {
        pagination: {
            current: 1,
            pageSize: 10,
        },
    },
};

const npaging = JSON.parse(JSON.stringify(pager));
npaging.filter = [
    {
        groupId: "statusFilter",
        groupType: FilterGroupType.OR,
        filter: [
            {
                attribute: "status",
                value: ReviewStatus.COMPLETED,
                operand: FilterOperand.EQUALS,
            },
        ],
    } as FilterGroup,
];

interface UserEnrichedDocumentReview extends ExpandedDocumentReview {
    user?: User;
    vendor?: Vendor;
}
const listReviewsWrapper = async (
    paging: SortFilterPaged<UserEnrichedDocumentReview>,
    vendorKeys?: string[],
    abort?: any
): Promise<PagerObject<UserEnrichedDocumentReview[]>> => {
    const reviews = await listReviews(paging, vendorKeys, abort);

    const userIds = reviews.data
        .map((d) => d.reviewerId)
        .filter(function (elem, index, self) {
            return elem !== -1 && index === self.indexOf(elem);
        });
    const users = [] as User[];
    if (userIds.length > 0) {
        (await getUsers(userIds)).forEach((u) => users.push(u));
    }

    const _vendorKeys = reviews.data.map((item) => item.vendorKey);
    let vendors = [] as Vendor[];
    if (_vendorKeys.length) {
        vendors = await getVendors(_vendorKeys);
    }

    const data = reviews.data.map((r) => {
        const fusers = users.filter((u) => u.id === r.reviewerId);
        const user = fusers !== undefined && fusers.length > 0 ? fusers[0] : undefined;
        const vendor = vendors.find((item) => item.vendorKey === r.vendorKey);

        return {
            ...r,
            user: user,
            vendor,
        } as UserEnrichedDocumentReview;
    });

    return {
        ...reviews,
        data: data,
    } as PagerObject<UserEnrichedDocumentReview[]>;
};

function getColumns(
    isSuperAdmin: boolean,
    onErrorClick?: (error: Error) => void,
    onDelete?: (serId: string) => void,
    onEdit?: (review: DocumentReview) => void,
    isOnboarding = false
): any {
    const columns = [
        {
            title: "File name",
            dataIndex: "document.name",
            key: "name",
            render: (value: any, row: DocumentReview) => {
                return <Link to={`/app/order/${row.orderFormId}`}>{row.document!.name}</Link>;
            },
        },
        {
            title: "Vendor",
            key: "vendorName",
            render: (_: any, row: UserEnrichedDocumentReview) => {
                return row.vendor?.name;
            },
        },
        {
            title: "Upload date",
            dataIndex: "document.uploadDate",
            key: "uploadDate",
            render: (value: any, row: DocumentReview) => {
                return formatDetailTimeString(row.document!.uploadDate);
            },
        },
        {
            title: "Pages",
            dataIndex: "document.pageCount",
            key: "pageCount",
            render: (value: any, row: DocumentReview) => {
                return row.document?.pageCount;
            },
        },
        ...(isOnboarding
            ? []
            : [
                  {
                      title: "Contract score",
                      dataIndex: "orderScore",
                      key: "orderScore",
                      render: (value: number, row: DocumentReview) => {
                          return (
                              <Space>
                                  <Link to={`/app/order/score/${row.orderFormId}`}>
                                      <SvgIcon name="group" size={12} />
                                  </Link>
                                  <SimulationScore score={roundToTwoDecimals(value)} />
                              </Space>
                          );
                      },
                  },
              ]),
        {
            title: "Reviewer",
            dataIndex: "user",
            key: "reviewerId",
            sorter: true,
            render: (value: any, row: UserEnrichedDocumentReview) => {
                if (row.user !== undefined) {
                    return (
                        <Tag color="default" key={`reviewers-${row.documentSerialId}`}>
                            {row.user!.name}
                        </Tag>
                    );
                }
                return (
                    <Tag color="warning" key={`reviewers-${row.documentSerialId}`}>
                        Unassigned
                    </Tag>
                );
            },
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            filter: true,
            filters: Object.values(ReviewStatus).map((v) => {
                return {
                    text: v,
                    value: v,
                };
            }),
            render: (value: any, row: DocumentReview) => {
                return (
                    <>
                        {ReviewStatusToTag(row.status)}{" "}
                        {isSuperAdmin && row.failedReason && row.status === ReviewStatus.FAILED ? (
                            <ExclamationCircleOutlined
                                style={{ color: "#FF4D4F" }}
                                onClick={() => onErrorClick?.(row.failedReason!)}
                            />
                        ) : null}
                    </>
                );
            },
        },
    ];
    if (isSuperAdmin) {
        columns.push({
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (value: any, row: DocumentReview) => {
                return (
                    <>
                        <Button
                            type="link"
                            icon={<SvgIcon name="edit" size={12} />}
                            onClick={() => onEdit?.(row)}
                        ></Button>

                        <Button
                            type="link"
                            icon={<SvgIcon name="trash" size={12} />}
                            onClick={() => onDelete?.(row.documentSerialId)}
                        ></Button>
                    </>
                );
            },
        });
    }

    return columns;
}

async function loadReviewsWightoutVendorFilter(
    paging: SortFilterPaged<UserEnrichedDocumentReview>,
    abort?: any
) {
    return await listReviewsWrapper(paging, undefined, abort);
}

export const ReviewListCompleted: React.FC = () => {
    const { me } = useUserState();
    const isMeSuperAdmin = isSuperAdmin(me.roles || []);

    const columns = getColumns(isMeSuperAdmin);

    const mainPanel = (
        <div className="contract-panel" style={{ paddingTop: 10 }}>
            <Card>
                <SortableFilteringTable<UserEnrichedDocumentReview>
                    load={loadReviewsWightoutVendorFilter}
                    size={"small"}
                    style={{ minHeight: 500 }}
                    columns={columns}
                    startingFilter={npaging as SortFilterPaged<UserEnrichedDocumentReview>}
                />
            </Card>
        </div>
    );
    return <MainLayout collapsed={true} selected={"reviewDone"} mainPanel={mainPanel} />;
};

interface ReviewListProps {
    refresh?: number;
    vendorKeys?: string[];
}

const ReviewList: React.FC<ReviewListProps> = ({ refresh, vendorKeys }) => {
    const { me } = useUserState();
    const isMeSuperAdmin = isSuperAdmin(me.roles || []);
    const isMeOnboarding = isOnboarding(me.roles || []);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorInfo, setErrorInfo] = useState({} as Error);
    const [showUpdate, setShowUpdate] = useState(false);
    const [review, setReview] = useState<DocumentReview>();
    const [reload, setReload] = useState(0);

    const loadData = useCallback(
        async (paging: SortFilterPaged<UserEnrichedDocumentReview>, abort?: any) => {
            return await listReviewsWrapper(paging, vendorKeys, abort);
        },
        [vendorKeys]
    );

    const handleErrorClick = (error: Error) => {
        setErrorInfo(error);
        setShowErrorModal(true);
    };

    const handleEdit = (review: DocumentReview) => {
        setReview(review);
        setShowUpdate(true);
    };

    const handleUpdate = async (review: DocumentReview) => {
        await editReview(review);
        setShowUpdate(false);
        reloadData();
    };

    const handleDelete = async (serId: string) => {
        Modal.confirm({
            title: "Remove File?",
            content: "Are you sure you want to remove the file?",
            onOk: async () => {
                await deleteReview(serId);
                reloadData();
            },
            okText: "Remove",
        });
    };

    const reloadData = () => {
        setReload(reload + 1);
    };

    useEffect(() => {
        reloadData();
    }, [refresh]);

    const columns = getColumns(
        isMeSuperAdmin,
        handleErrorClick,
        handleDelete,
        handleEdit,
        isMeOnboarding
    );
    return (
        <>
            <SortableFilteringTable<UserEnrichedDocumentReview>
                load={loadData}
                size={"small"}
                style={{ minHeight: 500 }}
                columns={columns}
                startingFilter={pager as SortFilterPaged<UserEnrichedDocumentReview>}
                refresh={reload}
            />
            <ErrorInfoModal
                open={showErrorModal}
                error={errorInfo}
                onCancel={() => setShowErrorModal(false)}
            />
            <ReviewUpdateModal
                open={showUpdate}
                review={review}
                onCancel={() => {
                    setShowUpdate(false);
                }}
                onOk={handleUpdate}
            />
        </>
    );
};

export default ReviewList;
